<template lang="">
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card class="p-3">
          <b-row>
            <h5 class="m-bold pl-2 w-100 pr-2 mb-2">
              Shortlisted Universities
              <i class="pt-5px fa-solid fa-heart-circle-plus pull-right primary-color pointer float-right ml-3" aria-hidden="true" title="Recommend Universities"  v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'" @click="openUnivListModal()"></i>
              <i class="pt-5px fa-solid fa-expand pull-right primary-color pointer ml-3" aria-hidden="true" title="University Shortlist Expand"  v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'" @click="openExpandedUnivRecoModal()"></i>
              <i class="pt-5px fa fa-cart-plus pull-right primary-color pointer float-right ml-3 cart_icon" aria-hidden="true" title="Student Universities List"  v-if="userData.user_role == 'USERROLE11114' || 'userData.user_role == USERROLE11111' || userData.user_role == 'USERROLE11118'" @click="openStudentUnivChoiceList()"></i>
            </h5>
          </b-row>

          <!--Expanded Recomendation List -->
          <b-modal size="xxl" :title="expandedUnivRecoModalTitle" v-model="showExpandedUnivRecoList" no-close-on-backdrop no-close-on-esc @hide="closeExpandedUnivRecoModal">
            <SpcmUnivList :key="refreshExpandedUnivRecoList" @emitOpenUnivListModal="openUnivListModal" :propSpcmObj="propSpcmObj" />
            <template #modal-footer="">
              <b-button size="sm" class="pull-left" variant="secondary" @click="closeExpandedUnivRecoModal()">
                {{modalCancelBtnText}}
              </b-button>
            </template>
          </b-modal><!--Expanded Recomendation List -->

          <!--University List -->
          <b-modal size="xl" :title="univListModalTitle" v-model="showUnivList" no-close-on-backdrop no-close-on-esc @hide="closeUnivListModal">
            <SpcmUnivList :propUserUnivReco="univRecoObjList" @emitAddUnivReco="emitAddUnivReco" />
            <template #modal-footer="">
              <b-button size="sm" class="pull-left" variant="secondary" @click="closeUnivListModal()">
                {{modalCancelBtnText}}
              </b-button>
            </template>
          </b-modal><!--University List -->

          <!-- Student Recommended University List -->
          <b-modal class="xxl" :title="studentUnivChoiceList" v-model="showStudentUniChoiceModal" no-close-on-backdrop no-close-on-esc hide-footer @hide="closeStudentUniChoiceModal">
            <form action="#">
              <div class="form-row">
               <div class="col-md-12 mb-3">
                  <textarea
                    v-model="vmOrganisationFormData.student_univ_choice"
                    type="textarea"
                    class="form-control textarea"
                    required
                  />
                </div><br>
                <button v-if="userData.user_role=='USERROLE11114'" type="button" class="btn btn-primary ml-1" @click="updateStudentUnivChoices(vmOrganisationFormData)">Save</button>
              </div>
            </form>
            <template #modal-footer="">
              <b-button size="sm" class="pull-left" variant="secondary" @click="closeStudentUniChoiceModal()">
                {{modalCancelBtnText}}
              </b-button>
            </template>
          </b-modal><!-- Student Recommended University List -->

          <div v-if="univRecoObjList && univRecoObjList.length > 0">
            <!-- NB: We are keeping the recommended universities in admission table -->
            <ol style="padding-inline-start:20px">
              <li class="mb-3" v-for="(univObj, index) in univRecoObjList" :key="index">
                <h6>
                  {{univObj.org_name}} &nbsp;
                  <i class="fa fa-circle warning-color check_icon" aria-hidden="true" title="University Appl. Recommended by Counselor" v-if="univObj.student_univ_choice_status === 2"></i>
                  <i class="fa fa-check success-color check_icon" aria-hidden="true" title="University Appl. Accepted by Student" v-else-if="univObj.student_univ_choice_status === 1"></i>
                  <i class="fa fa-close primary-color check_icon" aria-hidden="true" title="University Appl. Rejected by Student" v-else></i>
                  <span v-if="univObj.student_univ_choice_status === 2" class="pull-right">
                    <br>
                    <i class="fa fa-check success-color pointer" aria-hidden="true" title="Accept this University Appl. Choice" @click="student_univ_choice_status(univObj, 1)"></i>
                    <i class="fa fa-close primary-color pointer ml-3" aria-hidden="true" title="Reject this University Appl. Choice" @click="student_univ_choice_status(univObj, 0)"></i>
                  </span>
                </h6>
                <p class="mb-0 information-color" title="Selected Course/Program">
                  <small>
                    {{univObj.cour_name}}&nbsp;&nbsp;
                    <i class="fa fa-arrow-right pointer" v-if="univObj.cour_url && (userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118')" title="Go to Course Webpage" aria-hidden="true" @click="goToUnivCoursePage(univObj.cour_url)"></i>
                  </small>
                </p>
                <small>
                  <p class="mb-0 mt-1"><small>App. Deadline (Internal): <span class="information-color"><b>{{ univObj.org_application_deadline_internal|dateFormatDDMMYYYY }}</b></span></small></p>
                  <p class="mb-0"><span v-html="getApplicationDeadlines(univObj)"></span></p>
                </small>
                <span>
                  <i class="fa fa-chrome pointer" title="Go to University Website" aria-hidden="true"  v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'" @click="goToURL(univObj)"></i>
                  <i class="fa-solid fa-trash-can pointer ml-3" title="Remove recommendation" aria-hidden="true"  v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'" @click="removeUnivReco(univObj)"></i>
                  <i class="fa-solid fa-calendar pointer ml-3" title="Change University Deadlines For Student" aria-hidden="true"  v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'" @click="openUnivDeadlineModal(univObj)"></i>
                </span>
              </li>
            </ol>
          </div>
          <div v-else>
            Recommended universities not found recomendation.&nbsp;
            <span v-if="userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118'">
              <span class="pointer primary-color" @click="showUnivList=true">Click here</span> to recommend universities.
            </span>
          </div>
        </iq-card>
      </b-col>
    </b-row>

    <!--Applicable Deadline for this Student-->
    <b-modal
      v-model="showDeadlineModel"
      scrollable
      :title="cvDeadlineHeader"
      size="xxl"
      hide-footer
    >
      <div class="col-md-12 mb-3" v-if="this.org_application_deadlineList[univDeadlineObj.university_id] && Object.keys(this.org_application_deadlineList[univDeadlineObj.university_id]).length > 0">
        <label for="univApplicationDeadlineLabel">{{ univApplicationDeadlineLabel }}</label>&nbsp;<label class="primary-color font-size-16">*</label>
        <select id="univApplicationDeadlineLabel" v-model="univDeadlineObj.org_application_deadline_forstudent" class="form-control">
          <option disabled :value="null">Select Application Deadline For Student</option>
          <option :value="deadline" v-for="(i, deadline) of this.org_application_deadlineList[univDeadlineObj.university_id]" :key="deadline">{{i}}</option>
        </select>
      </div>
      <div class="mt-4">
        <b-btn type="button" variant="primary" class="pull-left" @click="editAdmission(univDeadlineObj)">
          {{cvAddAdmissionBtn}}
        </b-btn>
      </div>
    </b-modal><!--Applicable Deadline for this Student-->

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </b-container>
</template>
<script>
import { Admissions } from "../../../FackApi/api/admission"
import ApiResponse from "../../../Utils/apiResponse"
import SpcmUnivList from "./SpcmUnivList.vue"
import { Spcms } from "../../../FackApi/api/Spcm.js"

export default {
  name: "SpcmStudentUnivReco",
  components: {
    SpcmUnivList
  },
  data () {
    return {
      univRecoObjList: null,
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Shortlisted Universities",
      univListModalTitle: "University List",
      showExpandedUnivRecoList: false,
      showStudentUniChoiceModal: false,
      showUnivList: false,
      expandedUnivRecoModalTitle: "Shortlisted Universities",
      modalCancelBtnText: "Cancel",
      refreshExpandedUnivRecoList: 0,
      studentUnivChoiceList: "Student Recommended University List",
      vmOrganisationFormData: {},
      org_application_deadlineList: {},
      org_application_deadline_forstudent: null,
      showDeadlineModel: false,
      cvDeadlineHeader: "UpdateDeadline",
      univCourseObj: {
        cour_id: null
      },
      cvAddAdmissionBtn: "Save",
      univDeadlineObj: {},
      univApplicationDeadlineLabel: "Choose the Applicable Deadline for this Student"
    }
  },
  props: {
    propSpcmObj: {
      default: null,
      type: Object
    },
    propUserUnivReco: {
      type: Array,
      default: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  mounted () {
    this.spcmView()
    this.getUnivReco()
  },
  methods: {
    /**
     * getUnivReco
     */
    async getUnivReco () {
      try {
        const payload = {}
        if (this.$route.params.spcmId) {
          payload.gide_user_id = this.propSpcmObj.user_id
          payload.user_role = "USERROLE11114"
        }
        else {
          payload.gide_user_id = this.userData.user_id
          payload.user_role = this.userData.user_role
        }

        const admissionListResp = await Admissions.admission_dashboard(this, payload)
        if (admissionListResp.resp_status) {
          this.univRecoObjList = admissionListResp.resp_data.data
        }
        else {
          this.univRecoObjList = []
        }
      }
      catch (err) {
        console.error("Exception in getUnivReco and err: ", err.message)
      }
    },
    /**
     * addAdmission
     */
    async addAdmission (univObj) {
      try {
        const payload = {
          user_id: this.propSpcmObj.user_id,
          university_id: univObj.university_id ? univObj.university_id : univObj.org_id,
          adm_fields: JSON.stringify({
            "cour_id": univObj.cour_id,
            "cour_name": univObj.cour_name,
            "batch": "Not yet decided",
            "cour_degree_name": univObj.cour_degree_name
          }),
          cour_id: univObj.cour_id,
          org_application_deadline_forstudent: univObj.org_application_deadline_forstudent
        }

        const admissionAddResp = await Admissions.admissionAdd(this, payload)
        if (admissionAddResp.resp_status) {
          this.getUnivReco()
          this.refreshExpandedUnivRecoList++
          this.$emit("refreshScholarshipReco")
        }
      }
      catch (err) {
        console.error("Exception in addAdmission and err: ", err.message)
      }
    },
    /**
     * openExpandedUnivRecoModal
     */
    openExpandedUnivRecoModal () {
      this.showExpandedUnivRecoList = true
    },
    /**
     * closeExpandedUnivRecoModal
     */
    closeExpandedUnivRecoModal () {
      this.showExpandedUnivRecoList = false
      this.getUnivReco()
    },
    /**
     * openUnivListModal
     */
    openUnivListModal () {
      this.showUnivList = true
    },
    /**
     * closeUnivListModal
     */
    closeUnivListModal () {
      this.showUnivList = false
    },
    /**
     * emitAddUnivReco
     */
    emitAddUnivReco (univObj) {
      this.addAdmission(univObj)
    },
    /**
     * removeUnivReco
     */
    async removeUnivReco (univObj) {
      try {
        const univRecoDeleteResp = await Admissions.admissionDelete(this, univObj.adm_id, true)
        if (univRecoDeleteResp.resp_status) {
          this.getUnivReco()
          this.$emit("refreshScholarshipReco")
          return
        }
        else {
          ApiResponse.responseMessageDisplay(this, univRecoDeleteResp)
        }
      }
      catch (err) {
        console.error("Exception in removeUnivReco() and err: ", err.message)
      }
    },
    /**
     * goTo URL
     */
    goToURL (orgObj) {
      window.open(orgObj.org_website, "_blank")
    },
    /**
     * goToUnivCoursePage
     */
    goToUnivCoursePage (courUrl) {
      window.open(courUrl, "_blank")
    },
    /**
     * openStudentUnivChoiceList
    */
    async openStudentUnivChoiceList (item) {
      this.showStudentUniChoiceModal = true
      this.StudentUniChoice = item
    },
    /**
     * updateStudentUnivChoice
     */
    async updateStudentUnivChoices (studentUniChoiceObj) {
      try {
        this.cvLoadingStatus = true

        let payload = {
          gide_user_id: this.propSpcmObj.user_id,
          spcm_id: this.propSpcmObj.spcm_id,
          student_univ_choice_list: studentUniChoiceObj.student_univ_choice
        }

        let spcmAddResp = await Spcms.spcmEdit(this, payload)
        await ApiResponse.responseMessageDisplay(this, spcmAddResp)
        if (spcmAddResp && !spcmAddResp.resp_status) {
          return false
        }

        this.closeStudentUniChoiceModal()
      }
      catch (err) {
        console.error("Exception occurred at updateStudentUnivChoice() and Exception:", err.message)
      }
    },
    /**
     * closeStudentUniChoiceModal
    */
    closeStudentUniChoiceModal () {
      this.showStudentUniChoiceModal = false
    },
    /**
     *Accept/Rejected and Added Organisation
    */
    async student_univ_choice_status (univObj, status = 2) {
      let payload = {
        adm_id: univObj.adm_id,
        student_univ_choice_status: status
      }

      let univChoiceStatusResp = await Admissions.student_univ_choice_status(this, payload)
      if (univChoiceStatusResp.resp_status) {
        univObj.student_univ_choice_status = status
      }
      else {
        ApiResponse.responseMessageDisplay(this, univChoiceStatusResp)
      }
    },

    /**
     * spcmView
     */
    async spcmView () {
      try {
        let spcmId = this.propSpcmObj.spcm_id
        let spcmViewResp = await Spcms.spcmView(this, spcmId)
        if (spcmViewResp && spcmViewResp.resp_status) {
          this.vmOrganisationFormData.student_univ_choice = spcmViewResp.resp_data.data.student_univ_choice_list
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmView() and Exception:", err.message)
      }
    },
    /**
     * getApplicationDeadlines
     * If the Data Application Deadline data is in JSON string then parse and format XXXX
     * else just format
     * If the it is found that a particular deadline is tagged to a student then highlight the option with color
     * Build an array of org specific deadlines for the ADMIN/COUNSELOR to choose and set for a stuent
     */
    getApplicationDeadlines (orgObj, responseWithHTMLTags = "TAGS") {
      try {
        let deadlines = orgObj.org_application_deadline
        let deadlinesObj = null
        let deadlineStr = ""

        let orgId = orgObj.org_id ? orgObj.org_id : orgObj.university_id
        orgObj.org_id = orgId
        if (!this.org_application_deadlineList[orgId]) {
          this.org_application_deadlineList[orgId] = {}
        }

        if (!deadlines) {
          if (responseWithHTMLTags == "TAGS") {
            deadlineStr += `<small>Deadlines Not Found</small><br>`
          }
          else {
            deadlineStr += `Deadlines Not Found\n`
          }
        }
        else if (deadlines.includes("{")) {
          deadlinesObj = JSON.parse(deadlines)
          for (const key in deadlinesObj) {
            let value = deadlinesObj[key]

            if (!value || value.includes("null")) {
              value = "--"
            }

            if (responseWithHTMLTags == "TAGS") {
              if (orgObj.org_application_deadline_forstudent && key.includes(orgObj.org_application_deadline_forstudent)) {
                deadlineStr += `<small class="information-color">${key}: <b>${value}</b></small><br>` // Required While Viewing
              }
              else {
                deadlineStr += `<small>${key}: ${value}</small><br>` // Required While Viewing
              }
            }
            else {
              deadlineStr += `${key}: ${value}\n`
            }

            this.org_application_deadlineList[orgObj.org_id][key] = key
          }
        }
        else {
          if (responseWithHTMLTags == "TAGS") {
            if (deadlines.includes("\n")) {
              deadlines = deadlines.replaceAll("\n", ",").replaceAll("\r", "")
            }
            let deadlinesArr = deadlines.split(",")

            let key = ""
            let value = ""

            for (let deadline of deadlinesArr) {
              deadline = deadline.trim()
              key = deadline.split(":")[0]
              value = deadline.split(":")[1]

              if (key.length <= 0) {
                continue
              }

              if (!value || value.includes("null")) {
                value = "--"
              }

              this.org_application_deadlineList[orgObj.org_id][key] = key

              if (orgObj.org_application_deadline_forstudent && key.includes(orgObj.org_application_deadline_forstudent)) {
                deadlineStr += `<small class="information-color">${key}: <b>${value}</b></small><br>` // Required While Viewing
              }
              else {
                deadlineStr += `<small>${key}: ${value}</small><br>` // Required While Viewing
              }
            }
          }
          else {
            deadlineStr += `${deadlines}\n`
          }
        }

        return deadlineStr
      }
      catch (e) {
        console.error("Ex. getApplicationDeadlines:", e)
      }
    },
    /**
     * editAdmission
     */
    async editAdmission (univObj) {
      try {
        const payload = {
          user_id: this.propSpcmObj.user_id,
          adm_id: univObj.adm_id,
          university_id: univObj.university_id,
          org_application_deadline_forstudent: univObj.org_application_deadline_forstudent
        }

        const admissionEditResp = await Admissions.admissionEdit(this, payload)
        if (admissionEditResp.resp_status) {
          this.getUnivReco()
          this.refreshExpandedUnivRecoList++
          this.showDeadlineModel = false
        }
      }
      catch (err) {
        console.error("Exception in editAdmission and err: ", err.message)
      }
    },
    /**
     * openUnivDeadlineModal
     */
    openUnivDeadlineModal (univObj) {
      this.showDeadlineModel = true
      this.univDeadlineObj = univObj
    }
  }
}
</script>
<style>
ul li::marker {
  color: red;
}
ol li::marker {
  color: #000;
}
.textarea {
  min-height: 150px;
  line-height: 27px !important;
}
.check_icon{
  border: 2px solid;
  border-radius: 100%;
  padding: 1px 2px 2px 2px;
  margin-top: 3px;
  font-size: 10px;
  margin-right: 7px;
}
.cart_icon{
  font-size: 20px;
  position: relative;
  top: -3px;
}
</style>
