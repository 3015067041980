<template>
  <b-container fluid>
    <b-row>
      <b-col class="col-12" v-if="propGideContractObj.spcm_tnc == '0' && userData.user_role == 'USERROLE11114'" >
        <p class="tnc_warning">Scroll down and accept the counseling service Terms &amp; Conditons. <a href="#accept" style="font-weight: 700; color:#000">Click to Scroll Down</a></p>
      </b-col>
      <b-col lg="12">
          <p v-html="this.vmblogFormData.blog_desc"></p>
          <div class="col-md-12">
            <template v-if="vmblogFormData.blog_attach && vmblogFormData.blog_attach.length > 0">
              <hr/>
              <label>Download sample contract</label>
              <div v-for="(attachment, index) of vmblogFormData.blog_attach" :key="index">
                <div class="attachmentCol mr-4">
                  <span class="pointer" @click="openFile(attachment.url)">{{ index+1 }}.&nbsp;&nbsp;{{ attachment.fileName ? attachment.fileName.split("/").pop() : attachment.url.split("/").pop() }}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                  <img class="pointer" v-if="attachment.content" :src="pdfLogo" @click="openFile(attachment.url)">
                  <img v-else-if="attachment.fileName" :src="pdfLogo">
                  <img v-else alt="not found">
                </div>
              </div>
            </template>
          </div>
          <hr/>
          <form action="#" v-if="vmspcmFormData && Object.keys(vmspcmFormData).length > 0">
            <!-- Upload Documents -->
            <div class="row col-12 w-100">
              <div class="col-md-12" v-if="userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111'">
                <label for="validationspcm_contract">{{cvSpcmAttachmentLabel}}</label>&nbsp;&nbsp;
                <label for="excAttachmentFileInput" class="pointer primary-color">(Click here to upload)&nbsp;&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-upload"></i></label>
                <input type="file" :accept="allowedExtensions" class="dontShow" id="excAttachmentFileInput" @change="onExtraCurricularAttatachmentChange($event, vmspcmFormData, 'excAttachmentFileInput')">
              </div>

              <div class="col-md-12">
                <template v-if="vmspcmFormData.spcm_contract && vmspcmFormData.spcm_contract.length">
                  <div v-for="(attachment, index) of vmspcmFormData.spcm_contract" :key="index">
                    <div class="attachmentCol mr-4">
                      <span class="pointer" @click="openFile(attachment.url)">{{ index+1 }}.&nbsp;&nbsp;{{ attachment.fileName ? attachment.fileName.split("/").pop() : attachment.url.split("/").pop() }}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                      <img class="pointer" v-if="attachment.content" :src="pdfLogo" @click="openFile(attachment.url)">
                      <img v-else-if="attachment.fileName" :src="pdfLogo">
                      <img v-else alt="not found">
                      <span v-if="userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11111'" class="addIcon extraCloseIcon" @click="vmspcmFormData.spcm_contract.splice(index, 1)"><i class="fa-solid fa-circle-xmark"></i></span>
                    </div>
                  </div>
                </template>
              </div>
            </div><!-- Upload Documents -->

            <div class="form-group">
              <div class="col-12 mb-3 gide_inline_textorcheckbox" v-if="propGideContractObj.spcm_tnc == '1' && (userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11114')">
                <b-badge variant="success" title="TnC Accepted">TnC Accepted</b-badge>
              </div>
              <div class="col-12 mb-3 gide_inline_textorcheckbox" v-if="propGideContractObj.spcm_tnc == '0' && (userData.user_role == 'USERROLE11111' || userData.user_role == 'USERROLE11118' || userData.user_role == 'USERROLE11114')">
                <b-badge style="background: #e5252c!important" title="TnC Not Accepted"  class="mb-2 ml-1">TnC Not Accepted</b-badge>
              </div>
              <div id="accept" class="col-12 gide_inline_textorcheckbox" v-if="propGideContractObj.spcm_tnc == '0' && userData.user_role == 'USERROLE11114'">
                <input id="" v-model="vmspcmFormData.spcm_tnc" type="checkbox" class="form-control mr-2" />
                <label class="lable" for="spcm term and conditon">{{ cvtermsAndCondition }}</label>
              </div>
              <button v-if="cvLoadingStatus" type="button" class="btn btn-primary" :disabled="cvLoadingStatus">
                <b-spinner label="Spinning"></b-spinner>
              </button>
              <button type="button" class="btn btn-primary mt-2" v-if="(propGideContractObj.spcm_tnc == '1' && userData.user_role != 'USERROLE11114') || (propGideContractObj.spcm_tnc == '0' && userData.user_role == 'USERROLE11114')" @click="spcmEdit()" :disabled="vmspcmFormData.spcm_tnc !== true && vmspcmFormData.spcm_tnc !== 1">{{cvSubmitBtn}}</button>
            </div>
          </form>

          <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
            <div v-html="toastMsg"></div>
          </b-toast>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { blogs } from "../../../FackApi/api/blog"
import ApiResponse from "../../../Utils/apiResponse"
import GideContract from "../../../FackApi/json/GideContract.json"
import pdfLogo from "../../../assets_gide/img/logo/pdfLogo.png"
import { Spcms } from "../../../FackApi/api/Spcm.js"

export default {
  name: "GideContract",
  components: {
  },
  props: {
    propOpenedInModal: {
      type: Boolean,
      default: false
    },
    propGideContractObj: {
      type: Object,
      default: function () {
        return {
          "spcm_category": ""
        }
      }
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  data () {
    return {
      vmspcmFormData: { spcm_contract: [] },
      cvSubmitBtn: "Save",
      GideContract: GideContract,
      showModelblogView: false,
      cvViewModalHeader: "Contract view",
      cvSpcmAttachmentLabel: "Attach Documents to this Contract (Size Limit of 5mb per document)",
      cvbtnModalCancel: "Close",
      blogViewObj: null,
      showToast: false,
      toastVariant: "default",
      toastTitle: "Contract Uploaded",
      toastMsg: null,
      cvLoadingStatus: false,
      pdfLogo: pdfLogo,
      allowedExtensions: "application/pdf",
      vmblogFormData: { blog_attach: [] },
      cvtermsAndCondition: "Please Accept Counseling Terms & Conditions"
    }
  },
  mounted () {
    this.gc_id = this.GideContract[this.propGideContractObj.spcm_category]
    this.blogView()
    this.spcmView()
  },
  methods: {
    /**
     * blogView
     */
    async blogView () {
      try {
        let blogId = this.gc_id
        let blogViewResp = await blogs.blogView(this, blogId)

        if (blogViewResp && blogViewResp.resp_status) {
          this.vmblogFormData = blogViewResp.resp_data.data
          this.vmblogFormData.blog_attach = JSON.parse(this.vmblogFormData.blog_attach)
        }
      }
      catch (err) {
        console.error("Exception occurred at blogView() and Exception:", err.message)
      }
    },
    /**
     * onExtraCurricularAttatachmentChange
     */
    onExtraCurricularAttatachmentChange (event, extraCurricular, inputId) {
      const fileList = event.target.files

      if (fileList && fileList.length) {
        for (let file of fileList) {
          // Allowed file types
          let allowedExtensions = [
            "application/pdf", "image/png", "image/jpg", "image/jpeg", "image/gif", "image/webp",
            "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "text/plain", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "text/csv", "application/vnd.ms-powerpoint",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation"
          ]

          let maxSize = 5 * 1024 * 1024 // 5MB limit

          if (!allowedExtensions.includes(file.type)) {
            this.toastMsg = "Invalid file type. Please upload a supported file."
            this.toastVariant = "danger"
            this.showToast = true
            continue
          }

          if (file.size > maxSize) {
            this.toastMsg = "File size exceeds the 5MB limit."
            this.toastVariant = "danger"
            this.showToast = true
            continue
          }

          // Read file and store in extraCurricular
          let reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = async (e) => {
            file.url = e.currentTarget.result
            file.fileName = file.name

            if (!extraCurricular.spcm_contract) {
              extraCurricular.spcm_contract = []
            }

            if (typeof extraCurricular.spcm_contract === "string") {
              extraCurricular.spcm_contract = JSON.parse(extraCurricular.spcm_contract)
            }

            extraCurricular.spcm_contract.unshift(file)

            // Reset input field
            const ele = document.getElementById(inputId)
            if (ele) {
              ele.value = ""
            }
          }
        }
      }
    },
    /**
     * openFile
     */
    openFile (url) {
      if (url === "") {
        // empty url means that univ admin has not accepted the application
        this.showToast = true
        this.toastVariant = "danger"
        this.toastMsg = "This document can only be viewed after accepting the application"
        return
      }

      window.open(url, "_blank")
    },
    /**
     * Spcms
     */
    async spcmEdit () {
      try {
        this.cvLoadingStatus = true

        if (this.vmspcmFormData.spcm_tnc == true) {
          this.vmspcmFormData.spcm_tnc = 1
        }
        else {
          this.vmspcmFormData.spcm_tnc = 0
        }
        let payload = {
          spcm_id: this.propGideContractObj.spcm_id,
          spcm_contract: this.vmspcmFormData.spcm_contract,
          spcm_tnc: this.vmspcmFormData.spcm_tnc
        }
        let spcmAddResp = await Spcms.spcmEdit(this, payload)
        await ApiResponse.responseMessageDisplay(this, spcmAddResp)

        if (spcmAddResp && !spcmAddResp.resp_status) {
          return false
        }

        //  Emit if opened in Modal
        if (this.propOpenedInModal) {
          this.$emit("emitcloseGIDECounselingContractModal", payload)
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmEdit() and Exception:", err.message)
      }
      finally {
        this.cvLoadingStatus = false
      }
    },
    /**
     * spcmView
     */
    async spcmView () {
      try {
        let spcmId = this.propGideContractObj.spcm_id
        let spcmViewResp = await Spcms.spcmView(this, spcmId)

        if (spcmViewResp && spcmViewResp.resp_status) {
          this.vmspcmFormData = spcmViewResp.resp_data.data
          this.vmspcmFormData.spcm_contract = JSON.parse(this.vmspcmFormData.spcm_contract)
        }
      }
      catch (err) {
        console.error("Exception occurred at spcmView() and Exception:", err.message)
      }
    }
  }
}
</script>
<style lang="scss">
  .attachmentCol {
    img {
      height: 32px;
      width: 32px;
      object-fit: contain;
      border: 2px solid aliceblue;
      margin-bottom: 6px;
    }
  }
  .extraCloseIcon {
    font-size: 18px;
    position: relative;
    top: -15px;
    cursor: pointer;
    z-index: 99;
    margin-left: -9px;
    color: #e5252c;
  }
  .rigt_arrow{
    font-size: 20px;
    position: relative;
    top: 5px;
    margin-right: 10px;
    cursor: pointer;
  }
  .tnc_warning{
    padding-left: 15px;
    background-color: orange;
    padding: 5px;
    font-weight: 500;
    color: #000;
    border-radius: 5px;
  }
</style>
