<template>
  <div v-if="sessionList.length > 0">
    <div class="row">
      <div class="col-12">
        <span><b>{{ sessionTitle }}</b></span>&nbsp;
      </div>
      <div class="col-12">
        <span v-if="propPublicType == 1"><span class="warning-color pull-right">(Visible to Students & Parents)</span></span>
        <span v-else><span class="primary-color pull-right">Counselor Notes (For Private viewing only)</span></span>
      </div>
    </div>
    <div>
      <div>
        <form  class="post-text m-1 mt-2"  style="width: 100%; position: relative">
          <vue-editor v-model="vmNoteFormData.note_desc"></vue-editor>
        </form>
      </div>

      <button class="btn btn-primary d-block pull-right add_button mt-1" @click="addSpcmNote()" >Add</button>
    </div><br>

    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg"></div>
    </b-toast>
  </div>
  <div v-else class="primary-color">There is some problem. Please refresh the screen</div>
</template>

<script>
import { VueEditor } from "vue2-editor"
import { SpcmNotess } from "../FackApi/api/spcmNotes"
import ApiResponse from "../Utils/apiResponse.js"
import { EventBus } from "../main.js"

export default {
  name: "SpcmNoteAdd",
  props: {
    propPublicType: {
      default: true
    },
    propSpcmObj: {
      default: () => {
        return {}
      }
    }
  },
  components: {
    VueEditor
    // UserLogo
  },
  data () {
    return {
      vmNoteFormData: {
        note_desc: ""
      },
      showToast: false,
      toastTitle: "Counseling Session Notes",
      toastMsg: null,
      toastVariant: "",
      sessionList: [],
      sessionTitle: null
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  created () {
    EventBus.$on("sessionList", event => {
      this.sessionList = event || []
      // NB: The latest session is always present at the last index of the array, Assuming that the notes will always be added to the lates session
      if (this.sessionList.length > 0 && this.sessionList[this.sessionList.length - 1]) {
        this.sessionTitle = `${this.sessionList[this.sessionList.length - 1].session_title} Session`
      }
      else {
        this.sessionTitle = `New Session ${this.sessionList.length}. `
      }
    })
  },
  mounted () {
  },
  methods: {
    /**
     * add Spcm Note. This is the primary function for adding notes
     */
    async addSpcmNote () {
      try {
        const currentSession = this.sessionList.length > 0 ? this.sessionList[this.sessionList.length - 1] : this.sessionList[this.sessionList.length]

        let payload = {
          session_title: currentSession.session_title,
          spcm_id: this.propSpcmObj.spcm_id,
          note_type: 1, // 1 for note and 2 for comment
          note_desc: this.vmNoteFormData.note_desc,
          note_public_type: this.propPublicType ? 1 : 0,
          notes_module_obj_id: this.propSpcmObj.user_id,
          session_id: currentSession.session_id,
          counsellor_id: this.propSpcmObj.counsellor_id,
          gide_user_id: this.propSpcmObj.user_id
        }

        const spcmNoteResp = await SpcmNotess.spcmNotesAdd(this, payload)
        if (spcmNoteResp.resp_status) {
          this.vmNoteFormData.note_desc = ""
          this.$emit("emitNoteAdd", { ...spcmNoteResp.resp_data, session_title: currentSession.session_title, session_start_ts: currentSession.session_start_ts, propPublicType: this.propPublicType })
        }
        ApiResponse.responseMessageDisplay(this, spcmNoteResp)
      }
      catch (error) {
        console.error("Exception in addSpcmNote()", error)
      }
    }
  }
}
</script>

<style lang="scss" scoped >
  .add_button{
    margin-top: 10px;
  }
</style>
